<template>
  <b-form>
    <b-row>
      <b-col cols="12">
        <div class="d-flex flex-center p-1">
          <div class="d-flex flex-column flex-center">
            <ek-image-picker
              class="activity-info-img"
              required
              @imageDeleted="userDto.removeImage = true"
              :value="userDto.profileImage"
              @input="userDto.image = $event"
            ></ek-image-picker>
            <h6 class="my-1">{{ userDto.name }}</h6>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <ek-input-text
          name="الاسم الكامل"
          label="الاسم الكامل"
          :rules="[{ type: 'required' }]"
          v-model="userDto.name"
        />
      </b-col>
      <!-- <b-col cols="12">
                 <div class="d-flex align-items-center mt-1 mb-1">
                    <b-form-checkbox v-model="userDto.isEmailActive" @input="test($event)" switch> </b-form-checkbox>
                    <label for="">التسجيل باستخدام البريد الالكتروني</label>
                </div>
            </b-col> -->
      <b-col cols="12">
        <validation-provider
          #default="{ errors }"
          name="البريد الالكتروني"
          :rules="userDto.isEmailActive ? 'required|email' : 'email'"
          class="email-text-validator d-flex flex-column"
        >
          <div class="bold">البريد الالكتروني</div>
          <b-form-input
            label="البريد الالكتروني mt-1"
            v-model="userDto.contactInfo.email"
            placeholder="ادخل البريد الالكتروني"
            :state="errors.length > 0 ? false : null"
            class="text-input mt-1"
          >
          </b-form-input>
          <div class="error-input">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </b-col>
      <!-- <b-col cols="12">
                <validation-provider
                #default="{ errors }"
                name=" "
                :rules="! userDto.isEmailActive ? 'required|mobile' : 'mobile'"
                class="phone-text-validator d-flex flex-column mt-1"
                >   
                <div class="bold">
                رقم الهاتف
                </div>
                    <b-form-input
                        label="رقم الهاتف"
                        name="رقم الهاتف"
                        placeholder="رقم الهاتف"
                        v-model="
                            userDto.contactInfo.phoneNumber
                        "
                        :state="errors.length > 0 ? false : null"
                        class="text-input mt-1"
                    >
                    </b-form-input>
                    <div class="error-input">
                        {{
                            errors[0]
                                ? errors[0].includes("valid") ? "رقم الهاتف غير صالح " 
                                    : errors[0]
                                : ""
                        }}
                    </div>
                </validation-provider>
            </b-col> -->

      <b-col cols="12" class="mt-1">
        <ek-input-text
          name="userName"
          label="اسم المستخدم "
          :rules="[{ type: 'required' }]"
          v-model="userDto.contactInfo.userName"
        />
      </b-col>
      <b-col cols="12" class="mt-1">
        <ek-input-text
          name="password"
          label="كلمة المرور"
          :rules="[{ type: 'min:4', message: 'كلمة المرور قصيرة' }]"
          v-model="userDto.newPassword"
          type="password"
        />
      </b-col>

      <b-col cols="12">
        <ek-input-text
          type="password"
          name="تأكيد كلمة المرور"
          label="تأكيد كلمة المرور"
          :rules="[{ type: 'confirmed:password', message: 'كلمة المرور غير متطابقة' }]"
        />
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations(["Update_User_Email_Is_Active"]),
    test($event) {
      this.Update_User_Email_Is_Active($event);
    },
  },
  computed: {
    ...mapState({ userDto: ({ users }) => users.userDetails }),
  },
};
</script>

<style lange="scss" scoped>
.text-input {
  margin-top: 0.4rem !important;
}

.bold {
  color: rgb(94, 88, 115);
  font-family: "SSTArabic-Medium";
  font-size: 14px;
  font-weight: 400;
}

.error-input {
  color: rgb(234, 84, 85) !important;
  font-size: 0.857rem;
}
</style>
