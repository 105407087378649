<template>
    <div class="user-notification-orders">
        <section class="orders-table">
            <ek-table
                :items="subscriptions"
                :columns="columns"
                @details="navigateToNotificationOrderDetails"
                no_delete
                no_select
            >
                <template slot="items.startSub" slot-scope="{ value }">
                    {{
                        value
                            ? new Date(value)
                                  .toLocaleString("en-UK")
                                  .split(",")
                                  .reverse()
                                  .join(" ")
                            : "-"
                    }}
                </template>

                <template slot="items.endSub" slot-scope="{ value }">
                    {{
                        value
                            ? new Date(value)
                                  .toLocaleString("en-UK")
                                  .split(",")
                                  .reverse()
                                  .join(" ")
                            : "-"
                    }}
                </template>

                <template slot="items.details" slot-scope="x">
                    <unicon
                        width="15"
                        height="15"
                        name="eye"
                        :id="`tooltip-target-${x.props.row.originalIndex}`"
                    />

                    <b-tooltip
                        :target="`tooltip-target-${x.props.row.originalIndex}`"
                        triggers="hover"
                    >
                        <div v-for="(feature, index) in activityFeaturesToString(x.props.row.features)" 
                            :key="index">
                            {{ feature }}
                        </div>
                    </b-tooltip>
                </template>

                <template
                    slot="items.subscriptionStatus"
                    slot-scope="{ value }"
                >
                    <StatusBadge
                        class="orders-table__status-badge"
                        :statusList="activitySubscriptionList"
                        :selectedStatusNumber="value"
                    />
                </template>
            </ek-table>
        </section>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge";

export default {
    computed: {
        ...mapState({
            subscriptions: ({ users }) => users.subscriptions,
            activitySubscriptionList: (state) =>
                state.activities.activitySubscriptionList,
        }),
    },
    components: {
        StatusBadge,
    },
    props: {
        id: String
    },
    data: () => ({
        columns: [
            {
                label: " رقم الاشتراك",
                field: "subNumber",
            },
            {
                label: "بداية الاشتراك",
                field: "startSub",
            },
            {
                label: "نهاية الاشتراك",
                field: "endSub",
            },
            {
                label: "حالة الاشتراك",
                field: "subscriptionStatus",
            },
            {
                label: "الميزات",
                field: "details",
                sortable: false,
            },
        ],
        servicesTranslation: {
            Covid: "كوفيد",
            NearBy: "بالقرب من",
            JobSubmit: "طلب وظيفة"
        },
    }),
    methods: {
        ...mapMutations(["Reset_Orders_Dto"]),
        navigateToNotificationOrderDetails({ row }) {
            this.$router.push(`/admin/orders/notificationRequest/${row.id}`);
        },
        formatOrdersDate(startDate, endDate) {
            return `${new Date(startDate).toLocaleDateString()}-${new Date(
                endDate
            ).toLocaleDateString()}`;
        },
        beforeDestroy() {
            this.$store.commit("Reset_Search_Dto");
        },
        activityFeaturesToString(arr) {
            
            let newArr = []
            
            arr.forEach((item) => {
                newArr.push(this.servicesTranslation[item]);
            });

            return newArr;
        },
    },
};
</script>
