<template>
  <div>
    <b-card
      class="h-100"
      header-class="p-1"
      footer-class="p-75 border-0"
      body-class="p-0"
    >
      <template #header>
        <h6>التعليقات</h6>
        <b-button
          class="p-0 buttonAll"
          style="font-size: 13px; bottom: 5px; position: relative"
          variant="transparnt"
          @click="openModel()"
        >
          عرض الكل
        </b-button>
      </template>
      <div v-if="comments.length > 0">
        <div
          v-for="(item, index) in itemsPerView <= comments.length - 1 ? itemsPerView : 1"
          :key="item"
          class="d-flex border-top border-bottom p-50"
        >
          <b-avatar
            size="lg"
            class="mr-1"
            :src="comments[0].profileImage ? $store.getters['app/domainHost'] + '/' + comments[0].profileImage : '' "
          ></b-avatar>
          <div class="comment-previwe w-100 d-flex justify-content-between">
            <div>
              <small>{{ comments[index].commenterName }}</small>
              <p class="m-0">{{ comments[index].comment.substring(0, 50) }}...</p>
            </div>
            <span style="color: gray; font-size: 12px" class="align-self-center mt-2">
              {{ formatNotificationDate(comments[index].date) }}
            </span>
          </div>
        </div>
      </div>

      <div v-else class="d-flex align-items-center justify-content-center">
        <h4 style="position: relative; bottom: 22px">لايوجد تعليقات</h4>
      </div>
    </b-card>

    <ek-modal ref="reference" title="التعليقات" noFooter>
      <b-card cols="12" v-for="item in comments" :key="item.id" body-class="pb-0">
        <template #header>
          <div class="d-flex w-100 justify-content-between">
            <h5>{{ item.commenterName }}</h5>
            <span style="color: gray; font-size: 12px">
              {{ formatNotificationDate(item.date) }}
            </span>
          </div>
        </template>
        <app-collapse :title="'Comments'">
          <app-collapse-item
            :title="item.comment.substring(0, 50)"
            style="position: relative; bottom: 18px"
            >{{ item.comment }}</app-collapse-item
          >
        </app-collapse>
      </b-card>
    </ek-modal>
  </div>
</template>
<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  props: {
    comments: Array,
    title: String,
    itemsPerView: {
      type: Number,
      default: 1,
    },
  },
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  methods: {
    openModel() {
      this.$refs.reference.open();
    },
    formatNotificationDate(date) {
      let dete = `  ${new Date(date).toLocaleDateString()} `;
      return dete;
    },
  },
};
</script>
