<template>
    <b-card body-class="p-0" class="user-interaction-tabs p-0">
        <b-tabs justified>
            <b-tab title="تفاعلات المستخدم" title-item-class="none">
                <b-tabs justified>
                    <b-tab title="الزيارات">
                        <div
                            v-for="item in userConnects.visitings"
                            :key="item.accountId"
                        >
                            <UserVisitings :data="item" />
                        </div>
                    </b-tab>

                    <b-tab title="المتابعات">
                        <div
                            v-for="item in userConnects.followings"
                            :key="item.accountId"
                        >
                            <UserFollowings :data="item" />
                        </div>
                    </b-tab>
                </b-tabs>
            </b-tab>

            <b-tab title="تفاعلات الآخرين" title-item-class="none">
                <b-tabs justified>
                    <b-tab title="الزيارات">
                        <div
                            v-for="item in userConnects.visitors"
                            :key="item.accountId"
                        >
                            <UserVisitors :data="item" />
                        </div>
                    </b-tab>

                    <b-tab title="المتابعات">
                        <div
                            v-for="item in userConnects.followers"
                            :key="item.accountId"
                        >
                            <UserFollowers :data="item" />
                        </div>
                    </b-tab>
                </b-tabs>
            </b-tab>
        </b-tabs>
    </b-card>
</template>
<script>
import UserFollowers from "@admin/users/components/userInteractionsStats/otherInteractions/UserFollowers.vue";
import UserVisitors from "@admin/users/components/userInteractionsStats/otherInteractions/UserVisitors.vue";

import UserFollowings from "@admin/users/components/userInteractionsStats/userInteractions/UserFollowings.vue";
import UserVisitings from "@admin/users/components/userInteractionsStats/userInteractions/UserVisitings.vue";

export default {
    components: {
        UserFollowers,
        UserVisitors,
        UserFollowings,
        UserVisitings,
    },
    props: {
        userConnects: Object,
    },
};
</script>

<style lang="scss" scoped>
.user-interaction-tabs {
    &::v-deep {
        .none {
            a {
                &::after {
                    display: none !important;
                }
            }
        }
    }
}
</style>
