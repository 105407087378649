<template>
    <div class="orders">
        <div>
            <b-tabs justified>
                <b-tab
                    :title="
                        'الاشتراكات ' + '(' + UserSubscriptionOrdersCount + ')'
                    "
                    @click="setSubscriptionTabActive"
                >
                </b-tab>
                <b-tab
                    :title="
                        'الإشعارات ' + '(' + userNotificationOrdersCount + ')'
                    "
                    @click="setNotificationTabActive"
                >
                </b-tab>
                <b-tab
                    :title="'الوظائف ' + '(' + userJobOrdersCount + ')'"
                    @click="setJobTabActive"
                ></b-tab>
            </b-tabs>
        </div>
        <component v-bind:is="selectOrderTable" :id="id"/>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import UserSubscriptionOrdersTable from "./userTables/UserSubscriptionOrdersTable.vue";
import UserNotificationOrdersTable from "./userTables/UserNotificationOrdersTable.vue";
import UserJobOrdersTable from "./userTables/UserJobOrdersTable.vue";

export default {
    components: {
        UserSubscriptionOrdersTable,
        UserNotificationOrdersTable,
        UserJobOrdersTable,
    },
    props: {
        id: String
    },
    computed: {
        ...mapGetters([
            "UserSubscriptionOrdersCount",
            "userNotificationOrdersCount",
            "userJobOrdersCount",
        ]),
        ...mapState({
            activeUserOrderTab: (state) => state.users.activeUserOrderTab,
        }),
        selectOrderTable() {
            let component = "";
            switch (this.activeUserOrderTab) {
                case 0:
                    component = UserSubscriptionOrdersTable;
                    break;
                case 1:
                    component = UserNotificationOrdersTable;
                    break;
                case 2:
                    component = UserJobOrdersTable;
                    break;
                default:
                    component = UserSubscriptionOrdersTable;
            }
            return component;
        },
    },
    created() {
        this.Update_User_Orders_Active_Tab(0);
    },
    methods: {
        ...mapMutations([
            "Update_User_Orders_Active_Tab",
            "Reset_User_Notification_Orders",
            "Reset_User_Subscription_Orders",
            "Reset_User_Job_Orders"
        ]),
        setSubscriptionTabActive() {
            this.Update_User_Orders_Active_Tab(0);
        },
        setNotificationTabActive() {
            this.Update_User_Orders_Active_Tab(1);
        },
        setJobTabActive() {
            this.Update_User_Orders_Active_Tab(2);
        },
        beforeDestroy() {
            this.Update_User_Orders_Active_Tab(0);
            this.Reset_User_Notification_Orders();
            this.Reset_User_Subscription_Orders();
            this.Reset_User_Job_Orders();
            this.$store.commit("Reset_Search_Dto");
        },
    },
};
</script>
