<template>
    <div
        class="user-visitings-list border-top border-bottom p-1 d-flex align-items-center"
    >
        <div class="visitor-details d-flex align-items-center w-100">
            <div
                class="visitor-details__user-data d-flex align-items-center mr-auto"
            >
                <b-avatar
                    size="lg"
                    class="rounded-circle mr-1"
                    :src="data.imageProfile ? ($store.getters['app/domainHost'] + '/' + data.imageProfile) : '' "

                >
                </b-avatar>
                <div class="visitor-info mr-auto">
                    <h5>{{ data.accountName }}</h5>
                    <small>{{ data.commercialActivityName }}</small>
                </div>
            </div>
            <div v-if="data && data.chartOptions">
                <vue-apex-charts
                    height="50"
                    :options="data.chartOptions"
                    :series="data.series"
                />
            </div>

            <b-dropdown
                dropleft
                variant="flat"
                toggle-class="p-0"
                class="custom-dd p-0"
            >
                <template #button-content>
                    <unicon fill="#777" name="angle-left" />
                </template>

                <div
                    v-for="(item, index) in data.dateTimes"
                    :key="index"
                    class="user-visitings-list__item border-bottom p-1 d-flex justify-content-between"
                >
                    <div>
                        {{ new Date(item).toLocaleTimeString() }}
                    </div>
                    <div>
                        {{ new Date(item).toLocaleDateString("en-UK") }}
                    </div>
                </div>
            </b-dropdown>
        </div>
    </div>
</template>

<script>

import VueApexCharts from "vue-apexcharts";

export default {
    props: {
        data: {},
    },
    methods: {
        navigateToVisitorDetails(id) {
            this.$router.push(`/admin/users/details/${id}`);
        },
    },
    components: {
        VueApexCharts
    }
};
</script>

<style lang="scss" scoped>
.visitor-details {
    &__arrow-button {
        padding: 1px 1px !important;
    }
}

.user-visitings-list {
    &__item {
        width: 200px;
    }
}
</style>
