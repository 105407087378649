<template>
    <div class="border-top border-bottom p-1 d-flex align-items-center">
        <div class="follower-details d-flex align-items-center w-100">
            <div
                class="follower-details__user-data d-flex align-items-center mr-auto"
            >
                <b-avatar
                    rounded
                    size="sm"
                    fluid
                    class="follower-details__user-image rounded-corners mr-1"
                   :src="data.imagUrl ? ($store.getters['app/domainHost'] + '/' + data.imagUrl) : ''"

                >
                </b-avatar>
                <div class="follower-info mr-auto">
                    <h5>{{ data.accountName }}</h5>
                    <small>{{ data.commercialActivityName }}</small>
                </div>
            </div>

            <div class="d-flex align-items-center flex-column">
                <div class="follower-details__follow-date">
                    {{
                        new Date(data.dateTime)
                            .toLocaleDateString("en-UK")
                    }}
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {},
    },
    methods: {
        navigateToVisitorDetails(id) {
            this.$router.push(`/admin/users/details/${id}`);
        },
    },
};
</script>

<style lang="scss" scoped>
.follower-details {
    &__arrow-button {
        padding: 1px 1px !important;
    }

    &__user-image {
        height: 49px;
        width: 49px;
    }

    &__follow-date {
        font-size: 0.9rem;
    }

}
</style>
