<template>
  <b-card class="w-100 h-100" header-class="pb-0">
    <template #header>
      <div class="user-card-header d-flex">
        <b-img
          style="height: 100px; width: 100px; border-radius: 50%"
          :src="$store.getters['app/domainHost'] + '/' + userDetails.profileImage"
          class="act-card__img mt-0 obj-cover"
        ></b-img>
        <div class="user-info d-flex flex-column p-1">
          <h4 class="m-0 text-dark">{{ userDetails.name }}</h4>
          <span class="my-50">حساب محمي</span>
          <div class="d-flex w-100 justify-content-between" style="gap: 0.2rem">
            <b-button variant="flat-primary" class="p-25" @click="$refs['modal'].open()">
              <unicon name="edit" height="18px" fill="gray"></unicon>
            </b-button>
            <b-button
              variant="flat-primary"
              class="p-25"
              @click="deleteUser(userDetails.id)"
            >
              <unicon name="trash-alt" height="18px" fill="gray"></unicon>
            </b-button>
            <b-button variant="flat-primary" class="p-25" @click="toggleBlock">
              <unicon
                name="ban"
                height="18px"
                :fill="userDetails.isBlock ? 'red' : 'gray'"
              ></unicon>
            </b-button>
          </div>
        </div>
      </div>

      <ek-modal noHeader size="sm" ref="modal" editMode noDelete @edit="submit">
        <ValidationObserver ref="form">
          <user-info-form></user-info-form>
        </ValidationObserver>
      </ek-modal>
    </template>
    <div>
      <ek-input-text
        v-if="userDetails.contactInfo.email"
        :value="userDetails.contactInfo.email"
        readonly
        label="البريد الالكتروني"
        name="البريد الالكتروني"
      />
      <ek-input-text
        v-if="userDetails.countryId"
        :value="countries.find((el) => el.id == userDetails.countryId).langName"
        readonly
        textLabel="langName"
        :options="countries"
        label="الدولة"
        name="الدولة"
      />
      <ek-input-text
        v-if="userDetails.cityId"
        :value="cities.find((el) => el.id == userDetails.cityId).langName"
        readonly
        textLabel="langName"
        :selectable="() => false"
        :options="cities"
        label="المدينة"
        name="المدينة"
      />
      <ek-input-text
        :value="userDetails.contactInfo.userName"
        readonly
        fieldClass="text-left"
        dir="ltr"
        label="اسم المستخدم  "
        name="مستحدم  "
      />
      <ek-input-text
        readonly
        label="تاريخ التسجيل"
        name="تاريخ التسجيل"
        :value="formatDate(userDetails.dateCreate)"
      />
    </div>
    <div class="d-flex flex-column align-items-start" style="gap: 1rem">
      <b-dropdown dropleft variant="flat" toggle-class="p-0" class="custom-dd p-0">
        <template #button-content>
          <unicon name="user" fill="#777" height="16" />المعلومات الشخصية
          <unicon fill="#777" name="angle-left" />
        </template>

        <!-- contact links -->
        <b-dropdown-item
          v-if="userDetails.contactInfo.email != null"
          style="position: relative; float: left"
        >
          <div>
            {{ userDetails.contactInfo.email }}
            <unicon fill="#777" name="envelope" />
          </div>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="userDetails.contactInfo.phoneNumber != null"
          style="position: relative; float: left"
        >
          <div>
            {{ userDetails.contactInfo.phoneNumber }}
            <unicon fill="#777" name="phone" />
          </div>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="userDetails.contactInfo.birthDate != null"
          style="position: relative; float: left"
        >
          <div>
            {{ userDetails.contactInfo.birthDate }}
            <unicon fill="#777" name="calendar-alt" />
          </div>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="userDetails.contactInfo.gender != null"
          style="position: relative; float: left"
        >
          <div>
            {{ userDetails.contactInfo.gender }}
            <unicon fill="#777" name="user" />
          </div>
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown dropleft variant="flat" toggle-class="p-0" class="custom-dd p-0">
        <template #button-content>
          <unicon name="briefcase" fill="#777" height="16" />المعلومات المهنية
          <unicon fill="#777" name="angle-left" />
        </template>
        <!-- Job info -->

        <b-dropdown-item v-if="userDetails.personalInfo != null">{{
          userDetails.personalInfo.graduationDate
        }}</b-dropdown-item>
        <b-dropdown-item v-if="userDetails.personalInfo != null">{{
          userDetails.personalInfo.schoolName
        }}</b-dropdown-item>
        <b-dropdown-item v-if="userDetails.personalInfo != null">{{
          userDetails.personalInfo.skills
        }}</b-dropdown-item>
        <b-dropdown-item v-if="userDetails.personalInfo != null">{{
          userDetails.personalInfo.languages
        }}</b-dropdown-item>
        <b-dropdown-item v-if="userDetails.personalInfo != null">{{
          userDetails.personalInfo.cvUrl
        }}</b-dropdown-item>
      </b-dropdown>
      <b-dropdown dropleft variant="flat" toggle-class="p-0" class="custom-dd p-0">
        <template #button-content>
          <unicon name="comment-alt-message" fill="#777" height="16" />معلومات التواصل
          <unicon fill="#777" name="angle-left" />
        </template>
        <!-- Contact Links -->

        <div v-for="item in userDetails.socialMedias" :key="item.id">
          <b-dropdown-item style="float: left; position: relative">
            {{ item.title }} &nbsp; &nbsp; {{ item.content }}
            <unicon fill="gray" height="18px" :name="getIcon(item.title)"></unicon>
          </b-dropdown-item>
        </div>
      </b-dropdown>
    </div>
  </b-card>
</template>

<script>
import UserInfoForm from "./UserInfoForm.vue";

import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      items: [
        { title: "Facebook", content: "www.fcebook.com" },
        { title: "Whatsapp", content: "www.whatsapp.com" },
      ],
    };
  },
  components: {
    UserInfoForm,
  },
  computed: {
    ...mapState({
      userDetails: ({ users }) => users.userDetails,
      socialMedia: ({ settings }) => settings.socialMedia,
      cities: ({ locations }) => locations.cities,
      countries: ({ locations }) => locations.countries,
    }),
  },
  methods: {
    formatDate(date) {
      return `${new Date(date).toLocaleDateString()}`;
    },
    ...mapActions(["updateUser", "deleteUser", "unBlockAccount", "blockAccount"]),
    submit() {
      this.$refs["form"].validate().then((suc) => {
        if (suc) {
          this.updateUser({
            id: this.userDetails.id,
            name: this.userDetails.name,
            email: this.userDetails.contactInfo.email,
            newPassword: this.userDetails.newPassword,
            userName: this.userDetails.contactInfo.userName,
            removeImage: this.userDetails.removeImage,
            image: this.userDetails.image,
          });
          this.$refs.modal.close();
        }
      });
    },
    toggleBlock() {
      if (this.userDetails.isBlock) {
        this.$store.dispatch("unBlockAccount", this.userDetails.id);
        this.userDetails.isBlock = false;
      } else {
        this.blockAccount(this.userDetails.id);
        this.userDetails.isBlock = true;
      }
    },
    getIcon(name) {
      if (name == "Facebook") return "facebook-f";
      if (name == "Telegram") return "telegram-alt";
      if (name == "Youtube") return "youtube";
      if (name == "Instagram") return "instagram";
      if (name == "Twitter") return "twitter-alt";
      if (name == "GitHub") return "uniGithubAlt";
      if (name == "WhatsApp") return "uniWhatsapp";
      if (name == "LinkedIn") return "uniLinkedinAlt";
    },
  },
};
</script>

<style lang="scss">
.custom-dd {
  .dropdown-toggle::before {
    display: none !important;
  }
}
</style>
