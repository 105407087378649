<template>
  <div>
    <b-card class="card" body-class="p-3">
      <div style="white-space: nowrap">
        <b-row cols="1" cols-md="2" :cols-lg="cols">
          <b-col class="style-sm" v-if="stats.followers || stats.followers === 0">
            <div class="d-flex m-auto">
              <unicon class="icons1" name="user-check" fill="black"></unicon>
              <h6 class="font-weight-bolder" style="display: grid; text-align: center">
                {{ stats.followers }}
                <span style="white-space: nowrap">المتابعين</span>
              </h6>
            </div>
          </b-col>
          <b-col class="style-sm smoller" v-if="stats.visitors || stats.visitors === 0">
            <div class="d-flex m-auto">
              <unicon class="icons2" name="users-alt" fill="black"></unicon>
              <h6 class="font-weight-bolder" style="display: grid; text-align: center">
                {{ stats.visitors }}
                <span style="white-space: nowrap">الزوار</span>
              </h6>
            </div>
          </b-col>
          <b-col class="style-sm" v-if="stats.notifications || stats.notifications === 0">
            <div class="d-flex m-auto">
              <unicon class="icons3" name="bell" fill="black"></unicon>
              <h6 class="font-weight-bolder" style="display: grid; text-align: center">
                {{ stats.notifications }}
                <span>الإشعارات</span>
              </h6>
            </div>
          </b-col>
          <b-col class="style-sm medium" v-if="stats.services || stats.services === 0">
            <div class="d-flex m-auto">
              <unicon class="icons4" name="tv-retro" fill="black"></unicon>
              <h6 class="font-weight-bolder" style="display: grid; text-align: center">
                {{ stats.services }}
                <span>الإعلانات</span>
              </h6>
            </div>
          </b-col>

          <b-col class="style-sm" v-if="stats.jobs || stats.jobs === 0">
            <div class="d-flex m-auto">
              <unicon class="icons5" name="bag" fill="black"></unicon>
              <h6 class="font-weight-bolder" style="display: grid; text-align: center">
                {{ stats.jobs }}
                <span>الوظائف</span>
              </h6>
            </div>
          </b-col>

          <b-col class="style-sm" v-if="stats.followings || stats.followings === 0">
            <div class="d-flex m-auto">
              <unicon class="icons5" name="bag" fill="black"></unicon>
              <h6 class="font-weight-bolder" style="display: grid; text-align: center">
                {{ stats.followings }}
                <span>المتابعات</span>
              </h6>
            </div>
          </b-col>

          <b-col class="style-sm" v-if="stats.visitings || stats.visitings === 0">
            <div class="d-flex m-auto">
              <unicon class="icons5" name="bag" fill="black"></unicon>
              <h6 class="font-weight-bolder" style="display: grid; text-align: center">
                {{ stats.visitings }}
                <span>الزيارات</span>
              </h6>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
export default {
  props: ["stats", "cols"],
};
</script>
<style lang="scss" scoped>
.icons1 {
  padding: 10px;
  background-color: rgb(186, 220, 242);
  border-radius: 50%;
  position: relative;
  bottom: 2px;
  right: 10px;
}
.icons2 {
  padding: 9px;
  background-color: rgb(186, 220, 242);
  border-radius: 50%;
  position: relative;
  bottom: 2px;
  right: 10px;
}
.icons3 {
  padding: 9px;
  background-color: rgb(186, 220, 242);
  border-radius: 50%;
  position: relative;
  bottom: 2px;
  right: 10px;
}
.icons4 {
  padding: 10px;
  background-color: rgb(186, 220, 242);
  border-radius: 50%;
  position: relative;
  bottom: 2px;
  right: 10px;
}
.icons5 {
  padding: 10px;
  background-color: rgb(186, 220, 242);
  border-radius: 50%;
  position: relative;
  bottom: 2px;
  right: 10px;
}
.style-sm {
  @media (max-width: 768px) {
    margin: 2rem 0;
  }
}
</style>
