<template>
    <div class="border-top border-bottom p-1 d-flex align-items-center">
        <div class="follower-details d-flex align-items-center w-100">
            <div
                class="follower-details__user-data d-flex align-items-center mr-auto"
            >
                <b-avatar
                    rounded
                    size="sm"
                    fluid
                    class="follower-details__user-image rounded-corners mr-1"
                    :src="
                        data.imagUrl
                            ? $store.getters['app/domainHost'] +
                              '/' +
                              data.imagUrl
                            : ''
                    "
                >
                </b-avatar>
                <div class="follower-info mr-auto">
                    <h5>{{ data.accountName }}</h5>
                    <small>{{ data.commercialActivityName }}</small>
                </div>
            </div>

            <div class="d-flex align-items-center flex-column">
                <div class="follower-details__follow-date">
                    {{
                        new Date(data.dateTime)
                            .toLocaleString("en-UK")
                            .split(",")
                            .reverse()
                            .join(" ")
                    }}
                </div>
                <b-form-rating
                    readonly
                    v-model="data.rate"
                    variant="warning"
                    for="rating-md"
                    no-border
                ></b-form-rating>
            </div>

            <b-dropdown
                dropleft
                variant="flat"
                toggle-class="p-0"
                class="custom-dd p-0"
            >
                <template #button-content>
                    <unicon fill="#777" name="angle-left" />
                </template>

                <div
                    v-for="(item, index) in data.comments"
                    :key="index"
                    class="user-visitings-list__item border-bottom p-1"
                >
                    <b-row>
                        <b-col cols="7">
                            {{
                                item.isToggled
                                    ? item.comment
                                    : item.comment.substring(0, 50)
                            }}
                        </b-col>
                        <b-col cols="1">
                            <unicon
                                class="drop-down__toggle-comment-button"
                                :name="
                                    item.isToggled ? 'angle-up' : 'angle-down'
                                "
                                @click="toggleComment(data.accountId, index)"
                            />
                        </b-col>
                        <b-col cols="4">
                            {{
                                new Date(item.date)
                                    .toLocaleString("en-UK")
                                    .split(",")
                                    .reverse()
                                    .join(" ")
                            }}
                        </b-col>
                    </b-row>
                </div>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
    props: {
        data: {},
    },
    data: () => ({
        comments: [
            {
                comment:
                    "asdasdasd asdasdasd asdasdasd asdasdasd asdasdasd asdasdasd asdasdasd asdasdasd asdasdasdasdasdasd v  asdasdasd asdasdasd asdasdasdasdasdasd asdasdasd asdasdasd vasdasdasd",
                date: new Date(Date.now()),
            },
            {
                comment: "asdasdasd",
                date: new Date(Date.now()),
            },
            {
                comment: "asdasdasd",
                date: new Date(Date.now()),
            },
            {
                comment: "asdasdasd",
                date: new Date(Date.now()),
            },
        ],
    }),
    methods: {
        ...mapMutations(["Toggle_User_Followings_Comment"]),
        toggleComment(accountId, commentIndex) {
            this.Toggle_User_Followings_Comment({ accountId, commentIndex });
        },
    },
};
</script>

<style lang="scss" scoped>
.drop-down {
    &__toggle-comment-button {
        cursor: pointer;
    }
}
.follower-details {
    &__arrow-button {
        padding: 1px 1px !important;
    }

    &__user-image {
        height: 49px;
        width: 49px;
    }

    &__follow-date {
        font-size: 0.9rem;
    }

    @media only screen and (max-width: 1200px) {
        flex-direction: column;
        gap: 1rem;
        align-items: center;

        &__user-data {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }
    }

    @media only screen and (max-width: 767px) {
        flex-direction: row;

        &__user-data {
            flex: 1;
        }
    }
}

.custom-dd::v-deep {
    ul {
        width: 338px !important;
    }
}
</style>
