<template>
    <div class="user-notification-orders">
        <section class="orders-table">
            <ek-table
                :items="notifications"
                :columns="columns"
                @details="navigateToNotificationOrderDetails"
                no_delete
                no_select
            >
                <template slot="items.date" slot-scope="{ value }">
                    {{
                        value
                            ? new Date(value)
                                  .toLocaleString("en-UK")
                                  .split(",")
                                  .reverse()
                                  .join(" ")
                            : "-"
                    }}
                </template>
            </ek-table>
        </section>
    </div>
</template>

<script>
import { mapState, mapMutations} from "vuex";

export default {
    computed: {
        ...mapState({
            notifications: ({ users }) => users.notifications,
        }),
    },
    props: {
        id: String
    },
    data: () => ({
        columns: [
            {
                label: " عنوان الإشعار",
                field: "title",
            },
            {
                label: "مرسل الإشعار",
                field: "senderName",
            },
            {
                label: "تاريخ الإشعار",
                field: "date",
            },
            {
                label: "تفاصيل",
                field: "details",
                sortable: false,
            },
        ],
    }),
    methods: {
        ...mapMutations(["Reset_Orders_Dto"]),
        navigateToNotificationOrderDetails({ row }) {
            console.log("row", row)
            this.$router.push(`/admin/users/${this.id}/notifications/${row.id}`);
        },
        formatOrdersDate(startDate, endDate) {
            return `${new Date(startDate).toLocaleDateString()}-${new Date(
                endDate
            ).toLocaleDateString()}`;
        },
        beforeDestroy() {
            this.$store.commit("Reset_Search_Dto");
        },
    },
};
</script>
