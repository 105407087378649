<template>
    <div>
        <b-row>
            <b-col md="4" class="pr-0">
                <UserInfo />
            </b-col>
            <b-col md="8" class="mt-1 m-md-0">
                <b-row>
                    <b-col md="12">
                        <ActivityStatistics :stats="userStats" cols="4" />
                    </b-col>
                    <b-col md="12">
                        <UserComments
                            :itemsPerView="5"
                            :comments="comments"
                        ></UserComments>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row class="no-gutters mt-1" style="gap: 1rem">
            <b-col md="8">
                <b-card class="h-100 w100"><UserOrderTables :id="id" /> </b-card>
            </b-col>
            <b-col><UserInteractionsStats :userConnects="userConnects" /></b-col>
        </b-row>
    </div>
</template>
<script>
import UserComments from "@admin/activities/components/ActivityComments.vue";
import UserInfo from "../components/userDetails/UserInfo.vue";
import ActivityStatistics from "@admin/activities/components/ActivityStatistics.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import UserOrderTables from "../components/UserOrderTables.vue";
import UserInteractionsStats from "@admin/users/components/userInteractionsStats/UserInteractionsStats.vue";

export default {
    props: ["id"],
    components: {
        UserInfo,
        ActivityStatistics,
        UserComments,
        UserOrderTables,
        UserInteractionsStats
    },
    computed: {
        ...mapState({
            user: ({ users }) => users.userDetails,
            comments: ({ users }) => users.comments,
            notifications: ({ users }) => users.notifications,
            jobs: ({ users }) => users.jobs,
            //Following,Followers,Visitors,Visites
            userConnects: ({ users }) => users.userConnects,
        }),
        ...mapGetters(["userStats"]),
    },
    methods: {
        ...mapActions(["getUserDetails", "getUserConnects", "getUserAbout"]),
    },
    created() {
        this.getUserDetails(this.id);
        this.getUserConnects(this.id);
        this.getUserAbout(this.id);
    },
};
</script>
